import React, {  lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const StoreRoom = SuspenseWrapper(lazy(()=> import("components/inventory/StoreRoom")));
const ShopTypesList = SuspenseWrapper(lazy(()=> import("components/inventory/ShopTypesList")));
const ShopsList = SuspenseWrapper(lazy(()=> import("components/inventory/ShopsList")));
const ItemGroups = SuspenseWrapper(lazy(()=> import("components/inventory/ItemGroups")));
const ItemsCategoryList = SuspenseWrapper(lazy(()=> import("components/inventory/ItemCatList")));
const ItemList = SuspenseWrapper(lazy(()=> import("components/inventory/ItemList")));

const InventoryRoutes = {
    path: "inventory",
    element: <Outlet />,
    children: [
        {
            path: "storeroom",
            element: <StoreRoom />,
        },
        {
            path: "shop-types",
            element: <Outlet />,
            children: [
                {
                    path: "list",
                    element: <ShopTypesList />,
                },
            ],
        },
        {
            path: "shops",
            element: <Outlet />,
            children: [
                {
                    path: "list",
                    element: <ShopsList />,
                },
            ],
        },
        {
            path: "item-groups",
            element: <Outlet />,
            children: [
                {
                    path: "list",
                    element: <ItemGroups />,
                },
            ],
        },
        {
            path: "items-category",
            element: <Outlet />,
            children: [
                {
                    path: "list",
                    element: <ItemsCategoryList />,
                },
            ],
        },
        {
            path: "item",
            element: <Outlet />,
            children: [
                {
                    path: "list",
                    element: <ItemList />,
                },
            ],
        },
    ],
};

export default InventoryRoutes;