import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  useTheme,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Api from "../../../ajax";
import GenGrid from "../../common/GenGrid";
import ScreenHead from "../../common/ScreenHead";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MuiTable from "components/common/MuiTable";
import useMediaQuery from "@mui/material/useMediaQuery";

const api = new Api();

const columns = [
  {
    field: "credit_date_lbl",
    accessorKey: "credit_date_lbl",
    header: "Credit Date",
    editable: false,
    width: 130,
    size:120,
  },
  {
    field: "credit_type_lbl",
    accessorKey: "credit_type_lbl",
    header: "Credit Type",
    editable: false,
    width: 120,
    size:120,
  },
  {
    type: "number",
    accessorKey: "number",
    field: "purchase_amount",
    header: "Purchase Amount",
    editable: false,
    width: 160,
    size:120,
  },
  {
    field: "purchase_ref",
    accessorKey: "purchase_ref",
    header: "Purchase Ref",
    editable: false,
    width: 180,
    size:120,
  },
  {
    type: "number",
    field: "bf_credit",
    accessorKey: "bf_credit",
    header: "BF Credit",
    editable: false,
    width: 100,
    size:120,
  },
  {
    type: "number",
    field: "purchase_credit",
    accessorKey: "purchase_credit",
    header: "Purchase Credit",
    editable: false,
    width: 150,
    size:120,
  },
  {
    type: "number",
    field: "total_crd",
    accessorKey: "total_crd",
    header: "Total Credits",
    editable: false,
    width: 120,
    size:120,
  },
  {
    type: "number",
    field: "used_credit",
    accessorKey: "used_credit",
    header: "Used Credit",
    editable: false,
    width: 110,
    size:120,
  },
  {
    type: "number",
    field: "cf_credit",
    accessorKey: "cf_credit",
    header: "CF Credit",
    editable: false,
    width: 100,
    size:120,
  },
];

const CreditReport = () => {
  const theme = useTheme();
  const [grid, setGrid] = useState({ count: 0, list: [], hashes: {} });
  const [pageState, changePageState] = useState({
    size: 25,
    index: 0,
    search: "",
    from_date: "",
    to_date: "",
    sort: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  });
  const [isLoading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [fromDt, setFromDate] = useState(null);
  const [toDt, setToDate] = useState(null);
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));


  useEffect(() => {
    setLoading(true);

    api.post(
      "/sms/credits/list",
      { ...pageState,...pagination},
      (res) => {
        setLoading(false);
        if (res.s) setGrid(res.d);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, [pageState,pagination]);

  const onPageChange = (pageIndex, details) => {
    changePageState({ ...pageState, index: pageIndex });
  };

  const onPageSizeChange = (pageSize, details) => {
    changePageState({ ...pageState, size: pageSize });
  };

  const onSortModelChange = (model, details) => {
    changePageState({ ...pageState, sort: model });
  };

  const onChangeValueByName = (name, value) => {
    changePageState({ ...pageState, [name]: value });
  };

  return (
    <Stack style={{ padding: "8px 32px" }}>
      <ScreenHead title="SMS Credit Report" />
      <Box sx={{ flexGrow: 1, marginBottom: "16px" }}>
        <Grid container spacing={3}>
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            key={"fromdt" + pageState.from_date}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                value={fromDt}
                inputFormat="dd-MM-yyyy"
                closeOnSelect={true}
                maxDate={new Date()}
                onChange={(newValue) => {
                  if (newValue !== undefined && newValue !== null) {
                    setFromDate(newValue);
                    onChangeValueByName(
                      "from_date",
                      format(newValue, "yyyy-MM-dd")
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    autoComplete="off"
                    label="From Date"
                    placeholder="Select date"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {fromDt !== undefined && fromDt !== null && (
                            <IconButton
                              aria-label="search"
                              onClick={() => {
                                setFromDate(null);
                                onChangeValueByName("from_date", "");
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            key={"todt" + pageState.to_date}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                value={toDt}
                inputFormat="dd-MM-yyyy"
                closeOnSelect={true}
                maxDate={new Date()}
                onChange={(newValue) => {
                  if (newValue !== undefined && newValue !== null) {
                    setToDate(newValue);
                    onChangeValueByName(
                      "to_date",
                      format(newValue, "yyyy-MM-dd")
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    autoComplete="off"
                    label="To Date"
                    placeholder="Select date"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {toDt !== undefined && toDt !== null && (
                            <IconButton
                              aria-label="search"
                              onClick={() => {
                                setToDate(null);
                                onChangeValueByName("to_date", "");
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      {/* <GenGrid
        height="calc(100vh - 232px)"
        rows={grid.list}
        loading={loading}
        columns={columns}
        rowCount={grid.count}
        pageSize={pageState.size}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        selectionModel={selectedValues}
        checkboxSelection={false}
        onSelectionModelChange={(ids) => {
          setSelectedValues(ids);
        }}
      /> */}
      {isMobileView ? (<MuiTable
        columns={columns}
        data={grid.list}
        enableStickyHeader={true}
        rowCount={grid.count}
        enableBottomToolbar={true}
        enableColumnPinning={true}
        manualFiltering={true}
        manualPagination={true}
        manualSorting={false}
        enableSorting={false} 
        onPaginationChange={setPagination}
        state={{
          isLoading: isLoading,
          pagination
        }}
        initialState={{
          columnPinning: { left: ["mrt-row-actions"] },
        }}
        headerStyle={{ backgroundColor: "#E1EBFE" }}
        muiTableHeadCellProps={{
          style: {
            backgroundColor: "#E1EBFE",
            border: "1px solid #E9EAEC",
          },
        }}
      />
) : (<MuiTable
  columns={columns}
  data={grid.list}
  enableStickyHeader={true}
  rowCount={grid.count}
  enableBottomToolbar={true}
  enableColumnPinning={true}
  manualFiltering={true}
  manualPagination={true}
  manualSorting={false}
  enableSorting={false} 
  onPaginationChange={setPagination}
  state={{
    isLoading: isLoading,
    pagination
  }}
  initialState={{
    columnPinning: { left: ["mrt-row-actions", "credit_date_lbl","credit_type_lbl"] },
  }}
  headerStyle={{ backgroundColor: "#E1EBFE" }}
  muiTableHeadCellProps={{
    style: {
      backgroundColor: "#E1EBFE",
      border: "1px solid #E9EAEC",
    },
  }}
/>
)}

    </Stack>
  );
};

export default CreditReport;
